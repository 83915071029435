body {
  margin: 0;
  /* font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif !important; */
  font-family: sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.mx-1200 {
  max-width: 1200px;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

/* .main-root-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */

.bg-primary {
  background-color: #F5C82A !important;
}

.text-gray {
  color: #848484 !important;
}

.text-dark {
  color: #000000 !important;
}

.overflow-y-auto {
  overflow-y: auto;
}

.rounded-circle {
  border-radius: 50% !important;
}

.d-flex {
  display: flex !important;
}

.flex-1 {
  flex: 1;
}

.flex-column {
  flex-direction: column !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.image-fade-in-effect {
  animation: fadeIn 1s;
}

.image-fade-out-effect {
  animation: fadeOut 1s;
}

.d-none-animation {
  display: none !important;
  animation: fadeOut 1s;
}

.d-block-animation {
  display: block !important;
  animation: fadeIn 1s;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.user-select-none {
  user-select: none !important;
}

.position-relative {
  position: relative !important;
}

.position-initial {
  position: initial !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.capitalize {
  text-transform: capitalize !important;
}

.breadcrumb-active {
  color: #EFBA3A !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.text-error {
  color: #f44336 !important;
}

.font-weight-normal {
  font-weight: normal !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-18 {
  font-size: 18px !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.MuiAutocomplete-popper {
  z-index: 11111111111 !important;
}


.m-0 {
  margin: 0 !important;
}

.mt-0, .my-0 {
  margin-top: 0 !important;
}

.mr-0, .mx-0 {
  margin-right: 0 !important;
}

.mb-0, .my-0 {
  margin-bottom: 0 !important;
}

.ml-0, .mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1, .my-1 {
  margin-top: 0.25rem !important;
}

.mr-1, .mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1, .my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1, .mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2, .my-2 {
  margin-top: 0.5rem !important;
}

.mr-2, .mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2, .my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2, .mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3, .my-3 {
  margin-top: 1rem !important;
}

.mr-3, .mx-3 {
  margin-right: 1rem !important;
}

.mb-3, .my-3 {
  margin-bottom: 1rem !important;
}

.ml-3, .mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4, .my-4 {
  margin-top: 1.5rem !important;
}

.mr-4, .mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4, .my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4, .mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5, .my-5 {
  margin-top: 3rem !important;
}

.mr-5, .mx-5 {
  margin-right: 3rem !important;
}

.mb-5, .my-5 {
  margin-bottom: 3rem !important;
}

.ml-5, .mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0, .py-0 {
  padding-top: 0 !important;
}

.pr-0, .px-0 {
  padding-right: 0 !important;
}

.pb-0, .py-0 {
  padding-bottom: 0 !important;
}

.pl-0, .px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1, .py-1 {
  padding-top: 0.25rem !important;
}

.pr-1, .px-1 {
  padding-right: 0.25rem !important;
}

.pb-1, .py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1, .px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2, .py-2 {
  padding-top: 0.5rem !important;
}

.pr-2, .px-2 {
  padding-right: 0.5rem !important;
}

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2, .px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3, .py-3 {
  padding-top: 1rem !important;
}

.pr-3, .px-3 {
  padding-right: 1rem !important;
}

.pb-3, .py-3 {
  padding-bottom: 1rem !important;
}

.pl-3, .px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4, .py-4 {
  padding-top: 1.5rem !important;
}

.pr-4, .px-4 {
  padding-right: 1.5rem !important;
}

.pb-4, .py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4, .px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5, .py-5 {
  padding-top: 3rem !important;
}

.pr-5, .px-5 {
  padding-right: 3rem !important;
}

.pb-5, .py-5 {
  padding-bottom: 3rem !important;
}

.pl-5, .px-5 {
  padding-left: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

.min-width-0 {
  min-width: 0 !important;
}